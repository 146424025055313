import { DestroyRef, EffectRef, Inject, Injectable, Signal, effect } from '@angular/core';
import { PriceScaleCollection, PriceScaleExchange } from 'src/app/shared/models/availabilty/section.model';
import { AvailabilityService } from '../../availability.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../modal.service';
import { Event } from 'src/app/shared/models/event.model';
import { AssociationService } from '../../association.service';
import { AssociationComplete } from 'src/app/shared/models/association.model';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { exchangeConfig } from 'src/app/shared/models/exchange/exchange-config.model';
import { Observable, forkJoin, map, tap } from 'rxjs';
import { SeatsHoldCodes } from 'src/app/shared/models/availabilty/seat.model';
import { HoldCodes } from 'src/app/shared/models/exchange/hold-code.modal';
import { InformationModalParams } from 'src/app/shared/models/modal.model';

@Injectable({
    providedIn: 'root'
})
export class ExchangeService extends AvailabilityService<Event, PriceScaleCollection | string[]>{
    
    constructor(
        // Dependencias de la clase padre
        destroy:        DestroyRef,
        http:           HttpClient,
        route:          ActivatedRoute,
        router:         Router,
        modal:          ModalService,
        
        // Dependencias propias del servicio especializado
        @Inject(APP_CONFIG)
        private readonly appConfiguration:   Configuration,
        private readonly associationService: AssociationService
    ) {
        // Super Call
        super(destroy, http, route, router, modal);

        // Reseteamos los usuarios seleccionados al cargar el servicio ( para evitar triggerear el efecto getAvailability )
        this.associationService.resetSelected();
    }

    // CONFIGURACIÓN
    private readonly client:  string                             = this.getClientKey()                   // Llave de configuración del exchange
    private readonly config:  exchangeConfig['']                 = environment.exchange![this.client];   // Configuración del exchange
    private readonly users:   Signal<AssociationComplete[]>      = this.associationService.selectedData; // Usuarios seleccionados

    // API
    protected override routeParams:   string = 'event';
    protected override returnRoute:   string = 'exchange';
    protected override get rootUrl(): string {
        return '/ticket_exchange/events/'
    }

    // DATOS DE DISPONIBILIDAD POR TIPO DE COMPRADOR
    private _availabilityBuyerType!:    PriceScaleExchange; 
    public  _seatHoldCodes!:            SeatsHoldCodes;
    
    public get availabilityBuyerType(): PriceScaleExchange {
        return this._availabilityBuyerType;
    }

    public get seatHoldCodes():         SeatsHoldCodes {
        return this._seatHoldCodes;
    }

    // HOLD CODES
    public get holdCodes():             HoldCodes {
        return this.config.hold_codes;
    }

    // MÉTODOS OVERRIDE
    /**
     * Soobreescritura del método getAvailabiltyHandler para poder especializar el servicio
     * @param entity 
     * @param additionalId 
     */
    protected override getAvailabiltyHandler(entity: any, additionalId?: string | undefined): void {}
    
    /**
     * Método que obtiene la disponibilidad de un sector en base a un id de evento y un id de sector.
     * Transforma la respuesta para devolver un array de strings con la el id de sector + id de asiento, ya 
     * que en exchange, la disponibilidad de asientos viene con un formato diferente:
     * 
     * @example
     *   Id del sector: 
     *      123
     *   Respuesta:
     *      { HOLD-CODE: { seat_id: { id: seat_id, status: status } } }
     *      {1030: {12030: {id: XXX, status: "AVAILABLE"}}
     *   Devuelve: 
     *      ["123-12030"]
     * @param {string | number } event 
     * @param {string | number} id 
     * @returns {string []} string[] -- Ids de los asientos disponibles
     */
    public override getSectionAvailability(event: string|number, id: string|number): Observable<string[]> {
        
        let url: string = this.rootUrl + event + this.endpoint + id + '/';
        
        return this.http.get<SeatsHoldCodes>( url, {params: this.getAvailabilityParams()}).pipe(
            tap((data: SeatsHoldCodes) => this._seatHoldCodes = data),
            map((data: SeatsHoldCodes) => {
                
                // Declaramos un array de ids de asientos
                let seatsId: string[] = [];

                // Iteramos sobre los asientos y formateamos el id de asiento
                seatsId = Object.keys(data).reduce((acc, sectionKey) => {
                    const sectionSeats = Object.keys(data[sectionKey]).map(seatKey => id + '-' + seatKey);
                    return [...acc, ...sectionSeats];
                }, seatsId);
                
                return seatsId;
            }),
        )
    }

    /**
     * Lanzamos Modal de Error personalizado para el servicio de Exchange
     * @param {string} message 
     */
    protected override launchErrorModal(message: string): void {
        
        const modalParams: InformationModalParams = {
            title:  "System Message",
            content: message,
            onConfirm: () => this.router.navigate([this.returnRoute])
        }

        this.modal.createInformationModal(modalParams);
    }
    
    /**
     * Sobreescribimos el efecto original para poder especializar el servicio.
     * En este caso, cuando se selecciona un evento (entity), en vez de lanzar el método getAvailability,
     * esperamos a que la signal de usuarios cambie, es decir, una vez habiendo pasado por el componente 
     * select-friends y habiendo seleccionado los usuarios con los que se quiere entrar al proceso. 
     * Una vez seleccionados los usuarios, se preparan las peticiones al exchange para obtener la disponibilidad. 
     */
    protected override getAvailability: EffectRef = effect(() => {

        let entity:     boolean  = this.entitySelected() !== null,
            hasUsers:   boolean  = this.users().length > 0,
            isExchange: boolean  = location.href.includes(this.returnRoute);

        if( entity && hasUsers && isExchange ){
            this.prepareExchangeClients(this.users()!)
            return
        }

    })

    // MÉTODOS PRIVADOS
    /**
     * Getter que comprueba el nombre del cliente desde la configuración de la aplicación
     * y devuelve la llave de configuración del exchange en base al valor.
     * @returns {string} Llave de configuración del exchange
     */
    private getClientKey(): string {
        switch(this.appConfiguration.general.clientName){
            case 'Chelsea FC':
                return 'chelseafc';
            default:
                return 'local';
        }
    }

    /**
     * Prepara las peticiones al exchange para obtener la disponibilidad de los usuarios seleccionados.
     * 
     * Este método tiene varias etapas para preparar las peticiones al exchange:
     * 
     * 1: Instanciamos el array de usuarios seleccionados.
     * 2: Instanciamos un objeto de tipos de compradores. ADULT siempre será TRUE ya que siempre solicitamos su disponibilidad.
     * 3: Instanciamos un objeto de peticiones que contendrá las peticiones al exchange
     * 4: Iteramos sobre los usuarios seleccionados y obtenemos el tipo de comprador de cada uno mediante el método getClientBuyerType
     * 5: Iteramos sobre los tipos de compradores y creamos las peticiones al exchange para obtener la disponibilidad
     * 6: Una vez formateado el objeto de requests, llamamos al método getExchangeAvailabilityHandler que se encargará de lanzar las peticiones
     * 
     * @param {AssociationCollection} userCollection 
     */
    private prepareExchangeClients(userCollection: AssociationComplete[]): void {
        
        // Instanciamos un objeto de tipos de compradores y de requests
        let buyerTypes: {[key:string]: boolean}         = { ADULT: true },
            requests:   {[key:string]: Observable<any>} = {};	

        // Iteramos sobre los usuarios seleccionados y obtenemos el tipo de comprador de cada uno
        userCollection.forEach(user => {
            buyerTypes[this.getClientBuyerType(user.tdc_info.birthday)] = true;
        })
        
        // Iteramos sobre los tipos de compradores y rellenamos el objeto requests con las peticiones al exchange
        for(let entry of Object.entries(buyerTypes)){
           const [key]   = entry;
           requests[key] = this.getExchangeAvailability(key)
        }

        // Lanzamos las peticiones al exchange
        this.getExchangeAvailabilityHandler(requests);
    }

    /**
     * Método que devuelve el tipo de comprador en base a la fecha de nacimiento del usuario.
     * 
     * Este método recibe la fecha de nacimiento del usuario, y en base a la configuración del exchange,
     * comprueba en qué rango de fechas se encuentra la fecha de nacimiento del usuario y devuelve el tipo de comprador.
     * @param birthday 
     * @returns 
     */
    public getClientBuyerType(birthday: string | Date): string {
        
        // Obtenemos Buyer Types y la fecha de nacimiento del usuario en milisegundos
        const buyerTypes:   exchangeConfig[0]['buyer_types'] = this.config.buyer_types;
        const birthdayTime: number                           = new Date(birthday).getTime();

        // Iteramos sobre los tipos de compradores y comprobamos en qué rango de fechas se encuentra la fecha de nacimiento
        for(const key of Object.keys(buyerTypes)){
            if(key !== 'ADULT'){

                const dates = buyerTypes[key];
                let from    = new Date(dates.from).getTime(),
                    to      = dates.to === 'today' ? new Date().getTime() : new Date(dates.to).getTime();
                
                // Si la fecha de nacimiento se encuentra en el rango de fechas, devolvemos el tipo de comprador
                if(birthdayTime >= from && birthdayTime <= to) {
                    return key
                }
            }
        }

        // Si no se encuentra en ningún rango de fechas, devolvemos ADULT 
        return 'ADULT'
    }

    /**
     * Método que retorna la petición a la api del exchange para obtener la disponibilidad de un tipo de comprador.
     * @param {string} key 
     * @returns 
     */
    private getExchangeAvailability( key: string ): Observable<PriceScaleExchange> {
        return this.http.get<PriceScaleExchange>(`${this.rootUrl}${this.entitySelected()!.id}/availability/`,{ params: {buyer_type_name: key}})
    }

    /**
     * Método que se encarga de lanzar las peticiones al exchange y setear la disponibilidad de los sectores.
     * La respuesta viene anidada por tipo de comprador, sin embargo, nosotros guardamos los valores totales de disponibilidad. 
     * Para ello, asignamos un objeto vacio y pasamos los valores de disponibilidad a un array de disponibilidad.
     * 
     * Guardamos la respuesta original por tipo de comprador en la propiedad _availabilityBuyerType y seteamos la disponibilidad.
     * 
     * @example 
     *  {ADULT: {1120: {...}, 1121: {...}}, JUNIOR: {1120: {...}, 1121: {...}}}
     * 
     * @returns
     *  {1120: {...}, 1121: {...}}
     * 
     * @param requests 
     */
    private getExchangeAvailabilityHandler(requests: {[x:string]: Observable<PriceScaleCollection>}): void {
        forkJoin(requests).subscribe((data) => {
           
            // Declaramos un array de disponibilidad y obtenemos las llaves de los sectores
            const availability: PriceScaleCollection = Object.assign({}, ...Object.values(data));
            
            // Seteamos la disponibilidad por tipo de comprador y la general (DVM)
            this._availabilityBuyerType = data;
            this.setAvailability(availability);

        })
    }
    
}